import { InjectionToken, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '@environments/environment';

import { AngularFireAuthModule, PERSISTENCE } from '@angular/fire/compat/auth';
import { USE_DEVICE_LANGUAGE } from '@angular/fire/compat/auth';

// import {
//   provideAnalytics,
//   getAnalytics,
//   ScreenTrackingService,
//   UserTrackingService,
// } from '@angular/fire/analytics';
// import { provideAuth, getAuth } from '@angular/fire/auth';
// import { AuthModule } from './auth/auth.module';
import { AngularFireModule } from '@angular/fire/compat';
// import { provideDatabase, getDatabase } from '@angular/fire/database';
// import { provideFirestore, getFirestore } from '@angular/fire/firestore';
// import { provideFunctions, getFunctions } from '@angular/fire/functions';
// import { provideMessaging, getMessaging } from '@angular/fire/messaging';
// import { providePerformance, getPerformance } from '@angular/fire/performance';
// import {
//   provideRemoteConfig,
//   getRemoteConfig,
// } from '@angular/fire/remote-config';
// import { provideStorage, getStorage } from '@angular/fire/storage';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AfterLoginComponent } from './after-login/after-login.component';
import { AccountService } from '@app/services/account.service';
import { MessagingService } from '@app/services/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AuthorizedLayoutComponent } from './layout/authorized-layout/authorized-layout.component';
import { UnauthorizedLayoutComponent } from './layout/unauthorized-layout/unauthorized-layout.component';
import { ProjectsService } from './services/project.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideHttpClient } from '@angular/common/http';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
// import { WrappedRouterOutletDirective } from './layout/wrapped-router-outlet.directive';
// import { getMessaging, provideMessaging } from '@angular/fire/messaging';

@NgModule({
  declarations: [AppComponent, AfterLoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    // AuthModule,

    // provideFirebaseApp(() => initializeApp(environment.firebase)),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AuthorizedLayoutComponent,
    UnauthorizedLayoutComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ServiceWorkerModule.register('//service-worker/firebase-messaging-sw.js', {
      // enabled: environment.production,
      enabled: true,
      scope: 'firebase-cloud-messaging-push-scope',
    }),
    ServiceWorkerModule.register('/service-worker/firebase-messaging-sw.js', {
      // enabled: environment.production,
      enabled: true,
      scope: 'firebase-cloud-messaging-push-scope',
    }),
    ServiceWorkerModule.register('service-worker/firebase-messaging-sw.js', {
      // enabled: environment.production,
      enabled: true,
      scope: 'firebase-cloud-messaging-push-scope',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),

    // WrappedRouterOutletDirective,

    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAnalytics(() => getAnalytics()),
    // provideAuth(() => getAuth()),
    // provideDatabase(() => getDatabase()),
    // provideFirestore(() => getFirestore()),
    // provideFunctions(() => getFunctions()),
    // provideMessaging(() => getMessaging()),
    // providePerformance(() => getPerformance()),
    // provideRemoteConfig(() => getRemoteConfig()),
    // provideStorage(() => getStorage())
    // provideMessaging(() => getMessaging())
  ],
  providers: [
    // { provide: PERSISTENCE, useValue: 'session' },
    { provide: PERSISTENCE, useValue: 'local' },
    { provide: USE_DEVICE_LANGUAGE, useValue: true },
    // ScreenTrackingService, UserTrackingService
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    AccountService,
    MessagingService,
    ProjectsService,
    provideHttpClient(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
