<div class="h-100">
  <!-- [@routeAnimations]="getRouteAnimationData()"  -->

  <!-- <router-outlet></router-outlet> -->

  @switch (layoutService.layout$ | async) { @case (PageLayout.Authorized) {

  <!-- <wrapped-router-outlet /> -->
  <app-authorized-layout>
    <router-outlet />
  </app-authorized-layout>
  } @case (PageLayout.UnAuthorized) {
  <app-unauthorized-layout>
    <router-outlet />
  </app-unauthorized-layout>
  <!-- } @case (PageLayout.Error) {
    <error-layout>
      <router-outlet />
    </error-layout> -->
  } @default {
  <app-unauthorized-layout>
    <router-outlet />
  </app-unauthorized-layout>
  } }
</div>
